/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Layout from "../../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText, PrismicImage } from "@prismicio/react";
import SEO from "../../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const DealSinglePage = ({ data }) => {
  const dealData = data.prismicDealPage.data;
  console.log(dealData);
  const dealImage = getImage(dealData.image);
  //  const photos = [];
  //  projectData.gallery.map((item, index) => {
  //     const image = getImage(item.image);
  //     photos.push(image);
  //   });
  //   console.log("photos", photos);

  const [modalState, setModalState] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");

  const openModal = (src) => {
    console.log("src", src);
    //stop bg scrolling when modal open - done in global css above now
    // const pic = getImage(src.image);
    setModalImage(src.image);
    setModalName(src.name);
    setModalLink(src.link);
    // setModalName("yo dude");
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title={`${dealData.title.text}`} />
      <div
        sx={{
          fontSize: 4,
          px: [3, 2, 1],
          "& .photo-grid": {
            display: "flex",
            marginLeft: "-10px" /* gutter size offset */,
            width: "auto",

            ".photo-grid_column": {
              paddingLeft: "10px" /* gutter size */,
              backgroundClip: "padding-box",
            },
          },
        }}
      >
        <h1>{dealData.title.text}</h1>
        {dealImage && (
          <div
            sx={
              {
                //border: "1px solid black",
              }
            }
          >
            {/* <GatsbyImage
              image={dealImage}
              alt={dealData.image.alt}
              quality={90}
            /> */}
            <PrismicImage
              field={dealData.image}
              width={"100%"}
              imgixParams={{
                auto: false,
              }}
            />
          </div>
        )}
        <div
          sx={{
            color: "muted",
            lineHeight: 1.6,
          }}
        >
          <PrismicRichText field={dealData.text.richText} />
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(DealSinglePage);

export const pageQuery = graphql`
  query DealSinglePageQuery($uid: String!) {
    prismicDealPage(uid: { eq: $uid }) {
      _previewable
      data {
        image {
          alt
          gatsbyImageData(aspectRatio: 1.77)
          url
        }
        text {
          richText
          text
          raw
        }
        title {
          raw
          richText
          text
        }
      }
    }
  }
`;
